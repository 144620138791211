/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import {
  getAllConsultants,
  getTalentsByConsultant,
  getConsultantsByTalents,
  shareBeneficiary,
} from '../../../api/ConsultantApi';
import { useToaster } from '../../../hooks/useToaster';
import { Toaster } from '../../../components/atoms/Toaster/Toaster';

import { TalentClientCard } from '../../../components/organisms/TalentClientCard/TalentClientCard';
import { getAllClients } from '../../../api/ClientsApi';
import {
  addConsultantMission,
  deleteConsultantMision,
  deleteTalentMission,
  addTalentMission,
  updateMission,
  getAccompagnements,
} from '../../../api/MissionsApi';
import { addBeneficiaries } from '../../../api/BeneficiaryAPI';
import { getUser } from '../../../api/userAPI';

import BigCombo from '../../../components/molecules/BigCombo/BigCombo';
import DescriptionTextArea from '../../../components/molecules/DescriptionTextArea/DescriptionTextArea';
import Icon from '../../../components/atoms/Icon/Icon';
import InputField from '../../../components/molecules/InputField/InputField';
import './MissionContainer.scss';
import CircleButton from '../../../components/atoms/CircleButton/CircleButton';
import { AddTalentsPopin } from '../AddTalentsPopin/AddTalentsPopin';
import ComboField from '../../../components/molecules/ComboField/ComboField';
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton';

export const MissionContainer = ({
  missionProps,
  isMissionCompleted,
  sendMission,
  updatedMission,
  isNew = false,
  talents = [],
  setTalentsWithConsultant = () => {},
}) => {
  const user = useSelector(UserSelectors.user);
  const [mission, setMission] = useState(missionProps);
  const [consultantsDropdown, setConsultantsDropdown] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [clients, setClients] = useState();
  const [isEditable, setIsEditable] = useState();
  const [popinOpen, setPopinOpen] = useState(false);
  const [subRole, setSubRole] = useState();
  const [accompagnements, setAccompagnements] = useState();
  const [accompagnementSelected, setAccompagnementSelected] = useState(null);
  const [dispositifSelected, setDispositifSelected] = useState(null);
  const [dispositifTypes, setDispositifTypes] = useState();
  const { toasters, addToaster, removeToaster } = useToaster();
  const [talentState, setTalentState] = useState(talents);

  //Refactor Code

  // ------------------------     Accompagnements     ------------------------ //

  useEffect(() => {
    if (accompagnementSelected && accompagnementSelected.value && accompagnements) {
      const currentAccompagnement = accompagnements.filter(
        (accompagnement) => accompagnement?.object === accompagnementSelected?.value,
      );
      setDispositifTypes(currentAccompagnement[0].types);
      updateMissionState({ ...mission, accompaniment_type: accompagnementSelected?.value ?? '' });
    }
  }, [accompagnementSelected]);

  useEffect(() => {
    getAccompagnementsHandle();
  }, []);

  useEffect(() => {
    setTalentsWithConsultant(mission.talents);
  }, [mission]);

  const getAccompagnementsHandle = async () => {
    await getAccompagnements().then((res) => {
      const keys = Object.entries(res.profiles_types);
      let accompagnementsTMP = keys.map(([key, obj]) => {
        let types = [];
        if (obj.dispositif_type) {
          const k = Object.entries(obj.dispositif_type);
          types = k.map(([key, obj]) => {
            return { value: obj?.value, label: obj?.name, object: obj?.value };
          });
          types.unshift({ value: 'Aucun', label: 'Aucun', object: 'Aucun', types: [] });
        }
        return { value: obj.value, label: obj.name, object: key, types: types };
      });
      accompagnementsTMP.unshift({ value: 'Aucun', label: 'Aucun', object: 'Aucun', types: [] });
      setAccompagnements(accompagnementsTMP);
      setAccompagnementSelected(
        accompagnementsTMP?.filter(
          (accompagnement) => accompagnement?.object === mission?.accompaniment_type,
        )[0],
      );
      setDispositifTypes(
        accompagnementsTMP?.filter(
          (accompagnement) => accompagnement?.object === mission?.accompaniment_type,
        )[0]?.types,
      );
      setDispositifSelected(
        accompagnementsTMP
          ?.filter((accompagnement) => accompagnement?.object === mission?.accompaniment_type)[0]
          ?.types.filter((type) => type?.value === mission?.dispositif_type),
      );
    });
  };

  const handleChangeAccompagnement = (value) => {
    setAccompagnementSelected(value);
  };

  const handleChangeDispositifType = (value) => {
    setDispositifSelected(value);
    updateMissionState({ ...mission, dispositif_type: value?.value ?? '' });
    // updateMissionState({ ...mission, accompaniment_type: accompagnementSelected?.value ?? null, dispositif_type: value?.value ?? null });
  };

  // ------------------------     Missions     ------------------------ //

  /**
   * Update mission API
   */
  useEffect(() => {
    if (mission.id) updateMission(mission.id, mission);
    handleGetUser(user.id);
    setAccompagnementSelected(
      accompagnements?.filter(
        (accompagnement) => accompagnement?.object === mission.accompaniment_type,
      )[0],
    );
    setDispositifTypes(
      accompagnements?.filter(
        (accompagnement) => accompagnement?.object === mission?.accompaniment_type,
      )[0]?.types ?? null,
    );
    setDispositifSelected(
      accompagnements
        ?.filter((accompagnement) => accompagnement?.object === mission?.accompaniment_type)[0]
        ?.types.filter((type) => type?.value === mission?.dispositif_type)[0],
    );
  }, [mission]);

  /**
   * Update mission state
   */
  useEffect(() => {
    if (mission) {
      const { id } = mission;
      if (!mission.description) mission.description = ' ';
      if (!id) {
        const { name, client_id } = mission;
        mission.user_id = user.id;
        if (name && client_id && mission.user_id && mission.accompaniment_type) {
          isMissionCompleted(true);
          sendMission(mission);
        } else {
          isMissionCompleted(false);
        }
        if (missionProps) {
          updateMissionState(missionProps);
        }
      } else {
        const { name, client_id, responsable } = mission;
        if (name && client_id && responsable && mission.accompaniment_type) {
          isMissionCompleted(true);
          sendMission(mission);
        } else {
          isMissionCompleted(false);
        }
      }
    }
  }, [mission, missionProps]);

  const handleMissionShared = () => {
    updateMissionState({ ...mission, shared: !mission.shared });
  };

  /**
   * Update mission State
   */
  const updateMissionState = (uMission) => {
    if (missionProps && !mission.id) {
      sendMission(uMission);
      setMission(missionProps);
    } else {
      setMission(uMission);
    }
  };

  // ------------------------     User     ------------------------ //
  const handleGetUser = async (id) => {
    const userInfo = await getUser(id).then((res) => setSubRole(res.user.sub_role));
    return userInfo;
  };

  // ------------------------     Clients     ------------------------ //

  /**
   * Get all clients
   */
  const getClients = () => {
    getAllClients(user.id).then((response) => {
      if (!response.clients) return;
      const all_clients = response.length <= 0 ? [] : response.clients.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      });
      setClients(all_clients);
    });
  };

  // ------------------------     Consultants     ------------------------ //

  /**
   * Get all consultants
   */
  const getConsultants = () => {
    getAllConsultants(user.office_id).then((response) => {
      if (!response.consultants) return;
      const all_consultants = response.consultants.map((c) => {
        return {
          value: c.user.id,
          label: `${c.user.first_name} ${c.user.last_name}`,
        };
      });
      setConsultantsDropdown(all_consultants);
    });
  };

  /**
   * Check if user is already added
   * @param {Int} id
   * @returns
   */
  const checkConsultants = (id) => {
    return !!mission.consultants && mission.consultants.some((c) => c.id === id);
  };

  // ------------------------     Talents     ------------------------ //

  /**
   * Add Talents to mission API and check consultants of talents
   * @param {*} talentsArray
   */
  const handleAddTalents = async (talentsArray) => {
    if (mission && mission.id && talentsArray) {
      const talentsNeedCreated = {
        users: talentsArray
          .filter((t) => !t.id && t.email)
          .map((t) => ({
            first_name: t.first_name,
            last_name: t.last_name,
            email: t.email,
            gender: t.gender,
            consultant_id: t.consultant_id ? t.consultant_id : user.profile_id,
            accompaniment_type: accompagnementSelected?.value,
            dispositif_type: dispositifSelected?.value,
          })),
      };
      let new_talents = talentsNeedCreated ? await addBeneficiaries(talentsNeedCreated) : null;
      talentsArray = talentsArray.concat(new_talents.users);
      const groupedByEmail = talentsArray.reduce((acc, obj) => {
        acc[obj.email ?? 'none'] = acc[obj.email ?? 'none'] || [];
        acc[obj.email ?? 'none'].push(obj);
        return acc;
      }, {});
      const concatenatedObjects = Object.values(groupedByEmail)
        .map((group) => {
          const objWithCode200 = group.find((obj) => obj.response && obj.response.code === 200);
          if (objWithCode200) {
            const otherObj = group.find((obj) => obj !== objWithCode200);
            if (otherObj) {
              objWithCode200.consultant_userid = otherObj.consultant_userid;
            }
            return objWithCode200;
          } else {
            return group;
          }
        })
        .flat();

      const talentsToCreated = {
        users: concatenatedObjects
          .filter((t) => t.id || t.user_id)
          .map((t) => {
            return { user_id: t.id ? t.id : t.user_id };
          }),
      };
      if (talentsToCreated) {
        const responseTalents = await addTalentMission(mission.id, talentsToCreated);
        if (responseTalents && responseTalents.mission) {
          updateMissionState(responseTalents.mission);
        }
      }
      const consultantToAdd = {
        users: concatenatedObjects
          .filter((t) => t.consultant_userid)
          .map((t) => {
            return { user_id: t.consultant_userid };
          }),
      };
      if (consultantToAdd) {
        const responseConsultants = await addConsultantMission(mission.id, consultantToAdd);
        if (responseConsultants && responseConsultants.mission) {
          updateMissionState(responseConsultants.mission);
        }
      }
      setPopinOpen(false);
      // window.location.reload();
    }
  };

  /**
   *  Delete Talents and remove duplicate consultants
   * @param {*} talent_id
   */
  const handleDeleteTalent = async (talent_id) => {
    // const tmp2 = await getUserProfile(talent.id);
    const filteredTalents = mission.talents.filter((talent) => talent.id !== talent_id);
    const filteredConsultants = removeDuplicates(mission.consultants);
    // const tmp = await getAllConsultants(user.office_id);
    let consultantToRemove = [];
    const consultantsOfTalents = await getConsultantsByTalents(talent_id);

    consultantsOfTalents.consultants.forEach(async (e) => {
      const talents = await getTalentsByConsultant(e.id);
      const tmpTalent = [];
      for (const u of talents.beneficiaries) {
        let tmpT = filteredTalents.filter((t) => t.id === u.user_id);
        tmpTalent.push(...tmpT);
      }
      if (tmpTalent.length === 0) {
        consultantToRemove.push(e.user_id);
        await deleteConsultantMision(mission.id, e.user_id);
      }
    });
    const filteredConsultantsRemove = filteredConsultants.filter(
      (c) => !consultantToRemove.includes(c.id),
    );

    updateMissionState({
      ...mission,
      talents: filteredTalents,
      consultants: filteredConsultantsRemove,
    });
    deleteTalentMission(mission.id, talent_id);
  };

  const getToasters = (toaster) => {
    addToaster(toaster.title, toaster.message, toaster.type);
  };

  /**
   *  View Talents
   * @param {*} consultant
   */
  const handleViewTalent = async (consultant) => {
    if (mission && (mission.shared || subRole === 'consultant_admin')) {
      const userTmp = await getUser(consultant.id);
      try {
        if (userTmp && userTmp.user && userTmp.user.beneficiary_id) {
          const response = await shareBeneficiary({
            beneficiary_id: userTmp.user.beneficiary_id,
            consultant_id: user.profile_id,
          });
        }
        window.location.href = `/consultant/beneficiaries/${consultant.id}/profile`;
      } catch (error) {
        //Mettre le message d'erreur

        console.error('Erreur lors de la requête:', error);
      }
    } else {
      const isUser = user.beneficiaries.filter((b) => b.id === consultant.id);
      if (isUser.length) {
        window.location.href = `/consultant/beneficiaries/${consultant.id}/profile`;
      } else {
        //Mettre le message d'erreur
        addToaster('Erreur', "Vous n'êtes pas autorisé à voir ce profil", 'error');
      }
    }
  };

  // ------------------------     Input Handlers     ------------------------ //

  /**
   *  Handle Input On Change
   * @param {*} input
   */
  const handleInputOnChange = (input) => {
    const { id, value } = input;
    updateMissionState({ ...mission, [id]: value });
  };

  /**
   *  Handle Text Area On Change
   * @param {*} textarea
   */
  const handleTextAreaOnChange = (textarea) => {
    const { id, value } = textarea;
    updateMissionState({ ...mission, [id]: value });
  };

  /**
   *  Handle Dropdown On Change
   * @param {*} dropdown
   */
  const handleDropdownOnChange = (dropdown) => {
    const { id, value } = dropdown;
    if (id === 'consultants') {
      const consultantId = value.value;
      const data = { user_id: consultantId };

      if (consultants) {
        const isConsultantAlreadyAdded = consultants.some((c) => c.id === consultantId);
        if (!isConsultantAlreadyAdded) {
          addConsultantMission(mission.id ? mission.id : updatedMission.id, data).then(
            (response) => {
              const newConsultants = response.mission.consultants;
              updateMissionState({ ...mission, consultants: newConsultants });
              setConsultants(newConsultants);
            },
          );
        }
      } else {
        addConsultantMission(mission.id ? mission.id : updatedMission.id, data).then((response) => {
          const newConsultants = response.mission.consultants;
          updateMissionState({ ...mission, consultants: newConsultants });
          setConsultants(newConsultants);
        });
      }
    } else if (id === 'client_id') {
      updateMissionState({ ...mission, [id]: value.value });
    } else {
      updateMissionState({ ...mission, [id]: value.value });
    }
  };

  // ------------------------     Utils     ------------------------ //

  /**
   *  Remove duplicates
   * @param {*} array
   * @returns
   */
  const removeDuplicates = (array) => {
    let seen = {};
    return array.filter((item) => {
      return seen.hasOwnProperty(item.id) ? false : (seen[item.id] = true);
    });
  };

  /**
   * Get Clients and clients
   */
  useEffect(() => {
    mission && !mission.id && setMission({ ...mission, user_id: user.id });
    getClients();
  }, []);

  /**
   * Check if user is responsable and set isEditable
   */
  useEffect(() => {
    if (mission && mission.id) {
      if (user.id === mission?.responsable[0]?.id || subRole === 'consultant_admin') {
        setIsEditable(true);
      } else setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  }, [mission, user]);

  /**
   * Check if user is responsable
   */
  useEffect(() => {
    setConsultants(mission.consultants);
  }, [mission]);

  // ------------------------     User     ------------------------ //

  /**
   * Get consultants
   */
  useEffect(() => {
    getConsultants(user.office_id);
  }, [user]);

  // ------------------------     Renders     ------------------------ //

  /**
   *  Render Mission Informations
   * @returns
   */
  const renderMissionInformations = () => {
    const { name, description } = mission;

    let responsableMission = '';

    if (mission.responsable) {
      responsableMission = consultantsDropdown?.filter(
        (c) => c.value === mission?.responsable[0]?.id,
      )[0];
    } else {
      responsableMission = consultantsDropdown?.filter((c) => c.value === user.id)[0];
    }

    if (!consultantsDropdown || consultantsDropdown.length === 0) {
      responsableMission = { value: user.id, label: user.first_name + ' ' + user.last_name };
    } else if (!responsableMission) {
      responsableMission = {
        value: user.mission?.responsable[0]?.id,
        label: mission?.responsable[0]?.first_name + ' ' + mission?.responsable[0]?.last_name,
      };
    }

    const client = clients?.filter((e) => e.value === mission.client_id)[0];
    return (
      <div className="mission-informations">
        <h2>Présentation de la mission</h2>
        <InputField
          name={'name'}
          title={'Nom de la mission'}
          placeholder={'Nom de la mission...'}
          value={name ? name : ''}
          onChange={handleInputOnChange}
          required
          disabled={!isEditable}
        />
        <ComboField
          key={1}
          value={
            accompagnementSelected?.value ??
            (mission.accompaniment_type ? mission.accompaniment_type : 'Aucun')
          }
          title={"Type d'accompagnement"}
          name={"type d'accompagnement mission"}
          options={accompagnements ?? []}
          onChange={handleChangeAccompagnement}
          readOnly={!isEditable}
          required
        />
        {dispositifTypes?.length ? (
          <ComboField
            key={2}
            value={
              dispositifSelected?.value ??
              (mission.dispositif_type ? mission.dispositif_type : 'Aucun')
            }
            title={'type de dispositif'}
            name={'type de dispositif'}
            options={dispositifTypes ?? []}
            onChange={handleChangeDispositifType}
            readOnly={!isEditable}
          />
        ) : (
          ''
        )}

        <div>
          <label className="common-form-label">Description</label>
          <DescriptionTextArea
            name={'description'}
            value={description ? description : ''}
            onChange={handleTextAreaOnChange}
            maxLength={1500}
            disabled={!isEditable}
          />
        </div>
        <BigCombo
          id={'user_id'}
          title={'Responsable de la mission *'}
          value={responsableMission ? { label: responsableMission.label, value: responsableMission.label } : { label: 'undefined undefined' }}
          options={[]}
          onChange={handleDropdownOnChange}
          disabled={!isEditable}
        />
        {/* {responsableMission ? responsableMission.label : mission} */}
        <div className="mission-participants-client">
          <div className="mission-participants-title">
            <Icon icon="person" color={'accent'} />
            <h2>Client *</h2>
          </div>
          {clients && (
            <BigCombo
              id={'client_id'}
              value={client ? { label: client.label, value: '' } : { label: 'undefined undefined', value: '' }}
              options={clients}
              onChange={handleDropdownOnChange}
              disabled={!isEditable}
            />
          )}
        </div>
        {!isNew && <PrimaryButton
          id={'createGroup'}
          label={'Créer un groupe avec les talents de cette mission'}
          onClick={() => { window.location.href = `/consultant/beneficiaries/groups/new?user_id=${user.id}&client_id=${mission.client_id}&mission_id=${mission.id}` }} />}
      </div>
    );
  };

  /**
   *  Render Mission Participants
   * @returns
   */
  const renderMissionParticipants = () => {
    let consultantsMission = consultantsDropdown;

    if (mission.responsable) {
      consultantsMission = consultantsMission?.filter(
        (c) => c.value !== mission?.responsable[0]?.id,
      );
      consultantsMission = consultantsMission?.filter((c) => c.value !== user.id);
      consultantsMission = consultantsMission?.filter((c) => c.label !== 'undefined undefined');
    } else {
      consultantsMission = consultantsMission?.filter((c) => c.value !== user.id);
      consultantsMission = consultantsMission?.filter((c) => c.label !== 'undefined undefined');
    }

    return (
      <div className="mission-participants">
        {mission.id && (
          <div className="mission-participants-consultants">
            <div className="d-flex justify-content-between">
              <div className="mission-participants-title">
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Icon icon="people" color={'accent'} />
                    <h2>Talents </h2>
                  </div>
                  {subRole === 'consultant_admin' && (
                    // <PrimaryButton id={'share'} label={'Partager'} onClick={handleShare} />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        marginTop: '10px',
                      }}
                    >
                      <p className="share-talents">
                        Partager les talents à l'ensemble des consultants
                      </p>
                      <input
                        type="checkbox"
                        checked={mission.shared}
                        onChange={handleMissionShared}
                      />
                    </div>
                  )}
                </div>
                {/* Mettre un swipe on off */}
              </div>
              <CircleButton
                disabled={false}
                isActivated={true}
                icon={Icon.icon.PersonPlus}
                id={'buttonPlus'}
                onClick={() => setPopinOpen(true)}
              />
            </div>

            {/* <BigCombo
              id={'consultants'}
              value={''}
              options={consultantsMission}
              onChange={handleDropdownOnChange}
              disabled={!isEditable}
            /> */}
            {/* <div className="mission-participants-all-consultants">
              {consultants?.map((consultant, index) => (
                <ConsultantMissionCard
                  key={index}
                  consultant={consultant}
                  deleteConsultant={handleDeleteConsultant}
                  isEditable={isEditable}
                  onClick={isUserResponsable ? () => handleViewTalent(consultant) : null}
                />
              ))}
            </div> */}
            <div className="mission-participants-all-consultants">
              {talents
                ?.filter(
                  (
                    item,
                    index,
                    self, //TODO: Check if BUG Mission List
                  ) => index === self.findIndex((t) => t.id === item.id),
                )
                .map((talent, index) => (
                  <TalentClientCard
                    key={index}
                    talent={talent}
                    deleteTalent={handleDeleteTalent}
                    isEditable={isEditable}
                    onClick={() => handleViewTalent(talent)}
                    subRole={subRole}
                    mission={mission}
                    user={user}
                    isTalentView={false}
                  />
                ))}
            </div>
            {popinOpen && (
              <AddTalentsPopin
                open={popinOpen}
                onClose={() => setPopinOpen(false)}
                user={user}
                setMembers={handleAddTalents}
                talentsList={user.beneficiaries}
                currentList={mission.talents}
                subRole={subRole}
                mission={mission}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mission-container">
      {mission && renderMissionInformations()}
      {mission && renderMissionParticipants()}
      <div className="notification-container">
        {toasters.map((toaster) => (
          <Toaster
            key={toaster.id}
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            onClose={() => removeToaster(toaster.id)}
          />
        ))}
      </div>
    </div>
  );
};
